<template>
  <v-col class="px-0 py-0">
    <v-expand-transition>
      <v-col class="px-0 py-0" v-if="$route.name == 'blogComponent'">
        <v-row
          no-gutters
          align="center"
          style="margin-top: 20px"
          v-if="!showCreateNewBlog"
        >
          <v-col class="px-0 py-0" style="margin-right: 10px">
            <statistic-card
              :numbers="statistic.blogs"
              title="Новини"
              subtitle="У нас на сайті"
            />
          </v-col>
          <v-col
            class="px-0 py-0"
            style="margin-left: 10px; margin-right: 10px"
          >
            <statistic-card
              :numbers="statistic.view_count"
              title="Переглядів"
              subtitle="Усього переглянуто клієнтами"
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="center" style="margin-top: 40px">
          <v-col cols="4" class="px-0 py-0">
            <searchField
              label="Пошук новини"
              placeholder="Введіть тут.."
              @search="setSearchField"
            ></searchField>
          </v-col>
          <v-row no-gutters justify="end">
            <submit-button
              style="
                height: 48px !important;
                width: max-content;
                font-size: 16px;
              "
              @click="navigateTo('createBlog')"
              ><div
                class="adminPlusIcon"
                style="
                  margin-right: 8px;
                  background-color: white;
                  width: 20px;
                  height: 20px;
                "
              />
              Додати новину</submit-button
            >
          </v-row>
        </v-row>

        <v-row no-gutters align="center" style="margin-top: 20px">
          <div
            class="statusBox"
            :class="activeStatus == 'All' ? 'activeStatusBox' : ''"
            @click="activeStatus = 'All'"
          >
            Усі
          </div>
          <div
            class="statusBox"
            :class="activeStatus == 'Publish' ? 'activeStatusBox' : ''"
            @click="activeStatus = 'Publish'"
          >
            Активні
          </div>
          <div
            class="statusBox"
            :class="activeStatus == 'Archive' ? 'activeStatusBox' : ''"
            @click="activeStatus = 'Archive'"
          >
            Архівовані
          </div>
        </v-row>
        <div class="sectionBackground">
          <p class="sectionTitle" style="margin-bottom: 8px">Наші новини</p>
          <p class="sectionText" style="margin-bottom: 0px">
            Додавайте актуальні новини, які будуть корисні вашим клієнтам
          </p>
          <div class="sectionLine" style="margin: 20px 0px" />
          <loader v-if="showLoader" />
          <admin-blog-card
            v-else
            v-for="content in contentList"
            :key="content.id"
            :content="content"
            @editBlog="showEditBlog"
            @archiveContent="changeStatus"
            @deleteContent="deleteContent"
          />
        </div>
        <v-row no-gutters justify="center">
          <pagination
            :page="page"
            :quantityPage="quantityPage"
            @changePage="changePage"
          />
        </v-row>
      </v-col>
    </v-expand-transition>
    <v-expand-transition>
      <router-view v-if="$route.name !== 'blogComponent'" />
      <!-- <create-new-blog
        v-if="showCreateNewBlog"
        :isEdit="isEdit"
        :itemForEdit="itemForEdit"
        @deleteContent="deleteContent"
        @successCreate="
          (showLoader = true),
            (showCreateNewBlog = false),
            (isEdit = false),
            getContent()
        "
        @back="showCreateNewBlog = false"
      /> -->
    </v-expand-transition>
  </v-col>
</template>

<script>
import SubmitButton from "../../UI/Buttons/submitButton.vue";
import statisticCard from "../../UI/Cards/statisticCard.vue";
import AdminBlogCard from "./adminBlogCard.vue";
//import CreateNewBlog from "./createNewBlog.vue";
import searchField from "./../../UI/Fields/searchField.vue";
import Loader from "../../UI/Loader.vue";
import blogMixin from "./blogMixin";
import Pagination from "../../UI/pagination.vue";
export default {
  components: {
    statisticCard,
    SubmitButton,
    AdminBlogCard,
    // CreateNewBlog,
    searchField,
    Loader,
    Pagination,
  },
  mixins: [blogMixin],
  data: () => ({
    activeStatus: "All",
    showCreateNewBlog: false,
    isEdit: false,
    showLoader: true,
    itemForEdit: {},
    contentList: [],
    page: 1,
    quantityPage: 0,
    statistic: {},
    search: "",
  }),
  mounted() {
    this.getContent();
    this.getBlogStatistic();
  },
  methods: {
    navigateTo(route) {
      this.$router.push({ name: route });
    },
    showEditBlog(item) {
      this.itemForEdit = item;
      this.isEdit = true;
      this.showCreateNewBlog = true;
    },
    changePage(page) {
      this.page = page;
      this.showLoader = true;
      this.getContent();
    },
    setSearchField(search){
      this.search = search;
    }
  },
  watch: {
    page: {
      handler() {
        this.showLoader = true;
        this.getContent();
      },
    },
    showCreateNewBlog: {
      handler() {
        this.$vuetify.goTo(0, 0);
      },
    },
    "$route.path": {
      handler() {
        if (this.$route.name == "blogComponent") {
          this.showLoader = true;
          this.getContent();
        }
      },
    },
    search: {
      handler(){
        this.showLoader = true;
        this.getContent();
      }
    },
    activeStatus: {
      handler(){
        this.showLoader = true;
        this.getContent();
      }
    }
  },
};
</script>

<style>
</style>