var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"adminBlogCard"},[_c('v-row',{attrs:{"no-gutters":"","align":"start"}},[_c('div',{staticStyle:{"margin-right":"20px"}},[(_vm.content.is_popular == 'Yes')?_c('div',{staticClass:"popularBox"},[_vm._v(" Популярний ")]):_vm._e(),_c('img',{staticStyle:{"border-radius":"4px"},attrs:{"src":_vm.content?.images?.[0]?.path,"width":"280px","height":"180px","alt":"img"}})]),_c('div',{staticStyle:{"width":"calc(100% - 300px)"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},_vm._l((_vm.content.categories),function(category){return _c('div',{key:category.id,staticClass:"categoryBox"},[_vm._v(" "+_vm._s(category.translations.find((translate) => translate.lang == "ua") ?.name)+" ")])}),0),_c('p',{staticClass:"blogCardTitle",staticStyle:{"margin-top":"20px"}},[_vm._v(" "+_vm._s(_vm.content.translations.find((translate) => translate.lang == "ua") ?.title)+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.content.translations.find((translate) => translate.lang == 'ua')
            ?.content
        )}}),_c('v-row',{staticStyle:{"margin-top":"8px"},attrs:{"no-gutters":"","align":"center"}},[_c('span',{staticClass:"blogCardOption"},[_vm._v(_vm._s(new Date(_vm.content.published_at).toLocaleDateString("uk-UA", { year: "numeric", month: "2-digit", day: "2-digit", timeZone: 'Europe/Kiev' })))]),_c('v-icon',{staticStyle:{"margin-left":"20px","margin-right":"8px"},attrs:{"size":"20px","color":"#C0C0C0"}},[_vm._v("mdi-eye")]),_c('span',{staticClass:"blogCardOption"},[_vm._v(_vm._s(_vm.content.view_count))]),_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"end"}},[_c('div',{staticClass:"iconBackground",staticStyle:{"margin-right":"12px"}},[_c('div',{staticClass:"editIcon",on:{"click":function($event){return _vm.$router.push({
                  name: 'editBlog',
                  params: { id: _vm.content?.id },
                })}}})]),_c('div',{staticClass:"iconBackground",staticStyle:{"margin-right":"12px"},on:{"click":function($event){return _vm.$emit(
                'archiveContent',
                _vm.content.id,
                _vm.content.published_at !== null ? 'Archive' : 'Publish'
              )}}},[_c('div',{staticClass:"archiveIcon"})]),_c('div',{staticClass:"iconBackground",on:{"click":function($event){return _vm.$emit('deleteContent', _vm.content.id)}}},[_c('div',{staticClass:"deleteIcon"})])])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }