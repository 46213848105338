<template>
  <div class="adminBlogCard">
    <v-row no-gutters align="start">
      <div style="margin-right: 20px">
        <div class="popularBox" v-if="content.is_popular == 'Yes'">
          Популярний
        </div>
        <img
          :src="content?.images?.[0]?.path"
          width="280px"
          height="180px"
          alt="img"
          style="border-radius: 4px"
        />
      </div>
      <div style="width: calc(100% - 300px)">
        <v-row no-gutters align="center">
          <div
            class="categoryBox"
            v-for="category in content.categories"
            :key="category.id"
          >
            {{
              category.translations.find((translate) => translate.lang == "ua")
                ?.name
            }}
          </div>
        </v-row>
        <p class="blogCardTitle" style="margin-top: 20px">
          {{
            content.translations.find((translate) => translate.lang == "ua")
              ?.title
          }}
        </p>

        <div
          v-html="
            content.translations.find((translate) => translate.lang == 'ua')
              ?.content
          "
        ></div>
        <v-row no-gutters align="center" style="margin-top: 8px">
          <span class="blogCardOption">{{
            new Date(content.published_at).toLocaleDateString("uk-UA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
               timeZone: 'Europe/Kiev'
            })
          }}</span>
          <v-icon
            style="margin-left: 20px; margin-right: 8px"
            size="20px"
            color="#C0C0C0"
            >mdi-eye</v-icon
          >
          <span class="blogCardOption">{{ content.view_count }}</span>
          <v-row no-gutters align="center" justify="end">
            <div class="iconBackground" style="margin-right: 12px">
              <div
                class="editIcon"
                @click="
                  $router.push({
                    name: 'editBlog',
                    params: { id: content?.id },
                  })
                "
              />
            </div>
            <div
              class="iconBackground"
              style="margin-right: 12px"
              @click="
                $emit(
                  'archiveContent',
                  content.id,
                  content.published_at !== null ? 'Archive' : 'Publish'
                )
              "
            >
              <div class="archiveIcon" />
            </div>
            <div
              class="iconBackground"
              @click="$emit('deleteContent', content.id)"
            >
              <div class="deleteIcon" />
            </div>
          </v-row>
        </v-row>
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      require: true,
    },
  },
};
</script>

<style scoped>
.adminBlogCard {
  border-radius: 10px;
  background: #fff;
  padding: 16px;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-style: normal;
  line-height: normal;
  margin-bottom: 20px;
}
.blogCardTitle {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 12px;
}
.blogCardText {
  color: #4b5262;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.blogCardOption {
  color: #989898;
  font-size: 14px;
  font-weight: 400;
}
.categoryBox {
  border-radius: 10px;
  background: rgba(20, 79, 169, 0.1);
  padding: 4px 8px;
  width: max-content;
  height: max-content;
  font-size: 16px;
  font-weight: 400;
  margin-right: 12px;
  display: grid;
  place-items: center;
}
.popularBox {
  display: grid;
  place-items: center;
  padding: 4px 8px;
  border-radius: 10px;
  background: #fed500;
  color: #172b69;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: max-content;
  position: absolute;
  margin: 16px 0px 0px 16px;
}
</style>